@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  .h1 {
    @apply text-4xl font-medium;
  }
  h2,
  .h2 {
    @apply text-3xl font-medium;
  }
  h3,
  .h3 {
    @apply text-2xl font-medium;
  }
  h4,
  .h4 {
    @apply text-xl font-medium;
  }
  h5,
  .h5 {
    @apply text-lg font-medium;
  }
  h6,
  .h6 {
    @apply text-base font-medium;
  }

  .icon-button {
    @apply p-2 rounded-full aspect-square flex justify-center items-center w-9 cursor-pointer;
    @apply hover:elevation-4;

    .mat-icon {
      overflow: unset;
    }
  }

  .mat-size-1 {
    font-size: 1rem;
    width: inherit;
    height: 1.2rem;
    vertical-align: middle;
  }
}

@layer components {
  input {
    @apply focus:outline-0;
  }
}

@layer utilities {
  .dot-between {
    & + .dot-between {
      &::before {
        content: '、';
      }
    }
  }
}
