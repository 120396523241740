ul.my-table {
  overflow: auto;

  > li {
    @apply grid grid-cols-12 gap-2 p-2 border;
  }

  > li:nth-child(odd) {
    background: rgba(225, 224, 224, 0.3);
  }

  > li:first-child {
    background: rgba(225, 224, 224, 0.7);
  }
}

li.table-item {
  @apply grid grid-cols-12 gap-2 p-2 border;
}

.table-detail {
  li {
    display: flex;
    align-items: baseline;
    margin-bottom: 5px;

    h6 {
      white-space: nowrap;
    }
  }
}
