// .editable,
// [contenteditable] {
//   border-bottom: 0.4px solid;
//   min-width: 30px;
//   min-height: 1em;
//   display: inline-block;

//   &[disabled='true'] {
//     border-bottom: 0;
//     pointer-events: none;
//   }

//   &:hover {
//     // border-bottom: 2px dotted;
//     box-shadow: 0 0 0 5000px rgba(75, 75, 75, 0.06) inset;
//     position: relative;

//     &::before {
//       content: '✎';
//       position: absolute;
//       left: -25px;
//       top: -30px;
//       font-size: 32px;
//     }
//   }
// }

.ql-editor {
  width: 100%;
  min-height: 18em;
  border-bottom: 0;

  img {
    max-width: 100%;
  }

  &:hover {
    // border-bottom: 2px dotted;
    box-shadow: none;
  }

  a {
    color: #06c;
    text-decoration: underline;

    &:hover {
      color: lighten($color: #06c, $amount: 10%);
      text-decoration: underline;
    }
  }
}
