@use 'sass:map';
@use 'sass:math';
@import './scss/my-theme.variable';
@import './scss/my-tailwind';
@import './scss/_my-theme.variable';
@import './scss/component/bottom-fuzzy';

html,
body {
  height: 100%;
  // custom modal modal zIndex
  font-family: var(
    --mdc-typography-button-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
}

a {
  color: inherit;

  &.underline {
    color: #06c;
    text-decoration: underline !important;

    &:hover {
      color: lighten($color: #06c, $amount: 10%);
      text-decoration: underline !important;
    }
  }
}

i {
  -webkit-user-select: none;
  user-select: none;
}

// use for when image return error with google storage
img {
  min-height: 2px;
}

[hidden] {
  display: none !important;
}

google-map {
  height: 300px;
}

ngx-loading-bar {
  .ngx-spinner {
    @apply text-accent;
    border-color: unset;
  }
}

.avatar-img {
  display: inline-block;
  height: 40px !important;
  width: 40px !important;
}

.content-text {
  @apply break-all whitespace-pre-wrap;
}

.border-bottom {
  @apply border-b-[1px] border-solid border-zinc-200;
}
@import './scss/material.custom';
@import './scss/quill.custom';
@import './scss/table.custom';

.bg-transparent-png {
  background-image: url('./assets/img/transparent.png');
}

.under {
  position: relative;
}

.under {
  &::after {
    position: absolute;
    content: '';
    width: var(--under-width, 45px);
    height: 3px;

    @apply bg-accent;
    display: block;
    margin-top: var(--under-margin-top, 8px);
  }
}

.ball {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid white;
  opacity: 0.5;

  &.visible {
    opacity: 1;
  }
}

.cal-day-cell {
  overflow: hidden !important;
}

mat-icon {
  @apply flex-none;
}
