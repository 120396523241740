.bottom-fuzzy {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
    @apply bg-gradient-to-b from-white/0 from-10% via-white/90 via-50% to-white;
  }
}
